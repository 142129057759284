/**
 * Created by dpardell on 04/11/2016.
 */
var RemaingDaysGlobalColumn = function(filter, title, helper, holidays) {
    this.filter = filter;
    this.title = title;
    this.helper = helper;
    this.holidays = holidays;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        //TODO: Falta el tema del justificant del retard
        var content = '';
        var taskEnd = false;
        var workinDays = 0;
        if(full.endDate){
            taskEnd = true;
        }
        if(!taskEnd) {
            content += '<span class="label ' + helper.getWorkingDaysClass(data) + '">';
            if (data > 0) {
                content += '+';
            }
            content += data + "</span>";
        }
        return content;
    }
}

var TaskUserProfileColumn = function(filter, title, language, dataInRow, noLink) {
    this.filter = filter;
    this.title = title;
    this.language = language;
    this.dataInRow = dataInRow || false;
    this.noLink = noLink || false;

    this.getColumn = function() {
        return language;
    }
    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        var identifier = '';
        var name = '';

        if(dataInRow) {
            identifier = full.identifier;
            name += full.name + ' ' + full.surename1;
            if (full.surename2) {
                full += ' ' + full.surename2;
            }
        } else if(data){
            identifier = data.identifier;
            name += data.name + ' ' + data.surename1;
            if (data.surename2) {
                name += ' ' + data.surename2;
            }
        }
        if(identifier && name) {
            if(noLink){
                content = name;
            }else{
                content = '<a ui-sref="annexa.profile({idUser: \'' + identifier + '\'})" class="text-primary tresPuntets" title="' + name + '">' + name + '</a>';
            }
        } else if (full.profile) {
        	$linq(full.profile).foreach(function (x) { 
        		content += x[language] + ', ';
            });
        	content = content.slice(0, -2);
        } 
        return content;
    }
};
