
/**
 * Created by osirvent on 20/01/2016.
 */
angular
    .module('annexaApp')
    .controller('TasksController',['$state', '$filter', '$scope', 'GlobalDataFactory', 'TaskFactory', 'Language','api', '$stateParams', '$rootScope', 'TasksModals', 'RestService', 'AnnexaFormlyFactory', 'HelperService',  
        function($state, $filter, $scope, GlobalDataFactory, TaskFactory, Language, api, $stateParams, $rootScope, TasksModals, RestService, AnnexaFormlyFactory, HelperService) {
            $scope.languageColumn = Language.getActiveColumn();
            var paramsToFilter = [];

            $scope.reassignTask = function(data) {
                var modal = angular.copy(TasksModals.reassignTask);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                var reassignTaskComplete = function (){
                    var model = modal.annexaFormly.model.modal_body;

                    var taskComment = {
                        comments: model.comments,
                        privateComments: true,
                        createdUser: $rootScope.LoggedUser,
                        createdDate: new Date(),
                        task: { id: data }
                    };

                    if (model.assignTo && model.assignTo == 'user' && model.newUser) {
                        TaskFactory.reassignTaskUser(taskComment, model.newUser.id, data).then(function(data) {
                            modal.close();
                            $state.go('annexa.tasks');
                        }).catch(function (error) {
                            modal.alerts.push({msg:'global.task.reassignError'});
                        });
                    } else if (model.assignTo == 'profile' && model.newProfile) {
                        TaskFactory.reassignTaskProfile(taskComment, model.newProfile, data).then(function(data) {
                            modal.close();
                            $state.go('annexa.tasks');
                        }).catch(function (error) {
                            modal.alerts.push({msg:'global.task.reassignError'});
                        });
                    }
                };
                AnnexaFormlyFactory.showModal("modalReassignTask", modal, reassignTaskComplete, false);
            };

            $scope.expiredStates = [
                { id: 'expired', name: $filter('translate')('global.literals.outOfTime') },
                { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') }
            ];

            $scope.taskStatuses = TaskFactory.taskStatuses;
            $scope.taskStatusesHeader = angular.copy($scope.taskStatuses);
            $scope.taskStatusesHeader.unshift({
                id: '',
                icon: 'minus',
                style: 'text-black',
                name: $filter('translate')('global.literals.all'),
                orderStatus: 0
            });

            $scope.getSpecificStatuses = function (globalStatusList) {
                var specificStatuses = $linq(GlobalDataFactory.taskTypeStatuses).distinctBy("x => x." + $scope.languageColumn).orderBy("x => x." + $scope.languageColumn, linq.caseInsensitiveComparer).toArray();
                
                if (globalStatusList && globalStatusList.length > 0) {
                	specificStatuses = $linq(GlobalDataFactory.taskTypeStatuses).where(function(status){
	        			return $linq(globalStatusList).contains(status.globalStatus, function(x,y){
	        				if(x && x.id && y == x.id){
	        					return true;
	        				}else{
	        					return false;
	        				}
	        			});
            		}).distinctBy("x => x." + $scope.languageColumn).orderBy("x => x." + $scope.languageColumn, linq.caseInsensitiveComparer).toArray();
                }

                return HelperService.addAllSelect(angular.copy(specificStatuses), $scope.languageColumn, $scope.languageColumn);
            }
            
            $scope.headerIconTaskStatus = function(icon) {
                if($scope.taskStatuses && $scope.taskStatusesHeader.length > 0 && $scope.filterData.status) {
                    var status = $linq($scope.taskStatusesHeader).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.status + "'");
                    if(status) {
                        if(icon) {
                            return status.icon;
                        } else {
                            return status.style;
                        }
                    }
                }

                if(icon) {
                    return 'minus';
                } else {
                    return 'false';
                }
            }
            
            $scope.taskStatusTitle = function() {
                var content = '';
     
                content += '<div>';
                content += '    <div class="btn-group dropdown">';
                content += '        <button type="button" class="btn btn-sm white dropdown-toggle p-l-0" data-toggle="dropdown">';
                content += '            <span class="fa-stack">';
                content += '                <i class="fa fa-{{headerIconTaskStatus(true)}} {{headerIconTaskStatus(false)}} text-lg fa-stack-1x" aria-hidden="true"></i>';
                content += '            </span>';
                content += '            <span class="sr-only">{{\'global.literals.showOptions\'}}</span>';
                content += '        </button>';
                content += '        <div class="dropdown-menu">';
                content += '            <a class="dropdown-item" ng-repeat="status in taskStatusesHeader | orderBy:\'orderStatus\'" ng-click="setStatusTaskHeader(status);">';
                content += '                <span class="fa-stack">';
                content += '                    <i class="fa  fa-{{status.icon}} {{status.style}} text-lg fa-stack-1x"></i>';
                content += '                </span>';
                content += '                &nbsp;&nbsp;<span translate="global.literals.all" ng-if="status.id == \'\'"></span>';
                content += '                &nbsp;&nbsp;<span translate="{{status.name}}" ng-if="status.id != \'\'"></span>';
                content += '            </a>';
                content += '        </div>';
                content += '    </div>';
                content += '</div>';

                return content;
            }
            
            $scope.setStatusTaskHeader = function(status){
                $scope.filterData.status = ((!status.id || status.id == 0) ?  '' : status.id);
                var scopeAux = angular.element('#dataTableTask').scope();
                if(scopeAux && scopeAux.tableDefinition && scopeAux.tableDefinition.reloadInternalData){
                	scopeAux.changeStatusFromHeader = true;
                    scopeAux.tableDefinition.reloadInternalData(true, true);
                }
            }
            
            $scope.taskStatusRender = function(data, type, full, meta) {
                var content = '';

                if($scope.taskStatuses && $scope.taskStatuses.length > 0) {
                    var status = $linq($scope.taskStatuses).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                    if(status) {
                        content += '<div class="text-center">';
                        content += '    <span class="fa-stack" title=" ' + $filter('translate')(status.name) + '">';
                        content += '        <i class="fa fa-' + status.icon + ' ' + status.style + ' text-lg fa-stack-1x"></i>';
                        content += '    </span>';
                        content += '    <br><span>' + full.status[$scope.languageColumn] + '</span>';
                        content += '</div>';
                    }
                }

                return content;
            }
            
            $scope.filterData = { loggedUser: $rootScope.LoggedUser.id};

            $scope.searchTasks = function (value) {
                var valueOk = value;
                if(valueOk && valueOk.val){
                    valueOk = valueOk.val;
                }
                if(!valueOk) {
                    return [];
                }else if(valueOk != '*' && valueOk.length < 3){
                    return [];
                }else{
                    if(valueOk == '*'){
                        valueOk = '';
                    }
                    return RestService.loadData('common','User',valueOk).then(function(dataSearch) {
                        var usersLoad = [];
                        if(dataSearch.data &&  dataSearch.data.content && dataSearch.data.content.length > 0){
                            angular.forEach(JSOG.decode(dataSearch.data.content), function(val, key) {
                                var name = val.name+" "+val.surename1;
                                if(val.surename2){
                                    name = name+" "+val.surename2;
                                }
                                usersLoad.push({ 'id': val.id, 'user': val, 'value': name});
                            });
                        }
                        return usersLoad;
                    }).catch(function(){
                        return [];
                    })
                }
            };

            $scope.submit = function (val) {
                 $scope.$broadcast('filterData', {});
            };

            $scope.newTask = function(){
                $state.transitionTo('annexa.tasks.createdTasks.new');
            };

    }])
    .controller('ManageTasksController',['$filter','$state','$scope', 'TaskFactory','api', '$stateParams', '$rootScope', 'HeaderService', 'HelperService', 'TableFilterFactory', 'RouteStateFactory', 'RestService', 'GlobalDataFactory',
        function($filter, $state, $scope, TaskFactory, api, $stateParams, $rootScope, HeaderService, HelperService, TableFilterFactory, RouteStateFactory, RestService, GlobalDataFactory) {
            $scope.tfilter = [
            	{ id: 'taskNumber', type: 'text', order: 0, label: 'global.literals.code', preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','taskNumber'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','taskNumber') },
                { id: 'description', type: 'text', order: 1, label: 'global.literals.task_name', preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','description'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','description') },
                { id: 'createdUser', type: 'text', order: 2, label: 'global.literals.started_for', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','createdUser'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','createdUser')},
                { id: 'user', type: 'text', order: 3, label: 'global.literals.assigned_to', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','user'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','user')},
                { id: 'startDates', type: 'dateRange', order: 4, label: 'global.literals.startDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','startDates'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','startDates')},
                { id: 'endDates', type: 'dateRange', order: 5, label: 'global.literals.endDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','endDates'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','endDates')},
                { id: 'expired', type: 'select', order: 6, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: angular.copy($scope.expiredStates), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','expired',$scope.expiredStates,'id'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','expired')},
                { id: 'type', type: 'select', order: 7, label: 'global.literals.type', dataType: 'LOCAL', data: angular.copy(GlobalDataFactory.taskTypes), addAll: true, nameProperty: $scope.languageColumn, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','type',GlobalDataFactory.taskTypes,'id'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','type')},
                { id: 'status', type: 'select-multiple', order: 8, label: 'global.literals.globalStatus', dataType: 'LOCAL', data: angular.copy($scope.taskStatuses), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','status',$scope.taskStatuses,'id'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','status')},
                { id: 'specificStatus', type: 'select-multiple', order: 9, label: 'global.literals.status', dataType: 'LOCAL', data: $scope.getSpecificStatuses(), addAll: true, nameProperty: $scope.languageColumn, callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','specificStatus',$scope.getSpecificStatuses(),'id'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','specificStatus')},
                { id: 'thirdName', type: 'text', order: 10, label: 'global.thirds.literals.third', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','thirdName'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','thirdName') },
                { id: 'usersName', type: 'text', order: 11, label: 'global.users.list.boxTaskUsersToptitle', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','usersName'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','usersName') },
                { id: 'address', type: 'text', order: 12, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','address'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','address') },
                { id: 'email', type: 'text', order: 13, label: 'global.tasks.literals.email', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','email'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','email') },
                { id: 'phone', type: 'text', order: 14, label: 'global.tasks.literals.phone', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','phone'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','phone') },
                { id: 'meta', type: 'text', order: 15, label: 'global.literals.meta', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','meta'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','meta') },
				{ id: 'dossier', type: 'text', order: 16, label: 'global.literals.dossier', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','dossier'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','dossier') },
                { id: 'subtasks', type: 'checkbox', order: 17, label: 'global.tasks.literals.alsoSubtasks', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','subtasks')},
                { id: 'duplicatedTasks', type: 'checkbox', order: 18, label: 'global.tasks.literals.alsoDuplicatedTasks', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','duplicatedTasks')},
                { id: 'noThirds', type: 'checkbox', order: 19, label: 'global.tasks.literals.noThirds', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','noThirds')},
                { id: 'noAffectedUsers', type: 'checkbox', order: 20, label: 'global.users.list.noAffectedUsers', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','noAffectedUsers')},
                { id: 'allowedCreateExternally', type: 'checkbox', order: 21, label: 'global.tasks.literals.allowedCreateExternally', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','allowedCreateExternally')}
            ];
            var routeState = RouteStateFactory.getRouteState($state.current);
            if(routeState) {
                $scope.tfilter = routeState.state;
            }
            $scope.filterData.assigned = false;
            $scope.filterData.manage = true;
            $scope.numCanceledTask = 0;

            if($stateParams.type){
                var filterStatus = $linq($scope.tfilter).where("x => x.id == 'status'").toArray();
                var filterExpired= $linq($scope.tfilter).where("x => x.id == 'expired'").toArray();
                if($stateParams.type == 'C' && filterStatus) {
                	filterStatus[0].model = [{ id: 'PENDING', name: $filter('translate')('PENDING') }, { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') }];
                }else if($stateParams.type == 'CE' && filterStatus && filterExpired){
                	filterExpired[0].model = { id: 'expired', name: $filter('translate')('global.literals.outOfTime') };
                	filterStatus[0].model = [{ id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') }];
                }else if($stateParams.type == 'CNE' && filterStatus && filterExpired){
                	filterExpired[0].model = { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') };
                	filterStatus[0].model = [{ id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') }];
                }else if($stateParams.type == 'CP' && filterStatus){
                	filterStatus[0].model = [{ id: 'PENDING', name: $filter('translate')('PENDING') }];
                }else if($stateParams.type == 'CF' && filterStatus){
                	filterStatus[0].model = [{ id: 'COMPLETED', name: $filter('translate')('COMPLETED') }];
                }
            }
            $scope.getFilterCall = function() {
                var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);;
                return filterCall;
            };

            $scope.getFilterCallAux = function(){
                var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);

                filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                filterCallAux.assigned = $scope.filterData.assigned;
                filterCallAux.manage = $scope.filterData.manage;

                if(filterCallAux.expired){
                    if(filterCallAux.expired.id == 'expired'){
                        filterCallAux.expired = true;
                    }else if(filterCallAux.expired.id == 'noExpired'){
                        filterCallAux.expired = false;
                    }
                }
                
        		if($scope.changeStatusFromHeader){
            		var filterAux = $linq($scope.tfilter).singleOrDefault(undefined, "x => x.id == 'status'");
            		if($scope.filterData.status){
            			if(filterAux){
                			var status = $linq($scope.taskStatuses).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.status + "'");
                			filterCallAux.status = [status];
               				filterAux.model = filterCallAux.status;
                		}else{
                			filterCallAux.status = undefined;
               		    	filterAux.model = undefined;
                		}
                	}else{
                		filterCallAux.status = undefined;
            		    if(filterAux){
            		    	filterAux.model = undefined;
            		    }
            		}
            		$scope.changeStatusFromHeader = false;
            	}else if(filterCallAux.status && filterCallAux.status.length == 1){
            		if(filterCallAux.status[0].id != $scope.filterData.status){
            			$scope.filterData.status = filterCallAux.status[0].id;
            		}
            	}else if(!filterCallAux.status || filterCallAux.status.length != 1){
            		$scope.filterData.status = undefined;
            	}

        		var filterSpecificStatusAux = $linq($scope.tfilter).singleOrDefault(undefined, "x => x.id == 'specificStatus'");
   				filterSpecificStatusAux.data = $scope.getSpecificStatuses(filterCallAux.status);

   				if(filterCallAux.specificStatus && filterCallAux.specificStatus.length > 0){
   					var specificListFilter = [];
   	                _.forEach(filterCallAux.specificStatus, function(value, index) {
   	   					if($linq(filterSpecificStatusAux.data).contains(value, function(x,y){
   	   						if(x && x.id && y.id == x.id) { return true; } else { return false; } 
   	   					})) {
   	   						specificListFilter.push({id: value[$scope.languageColumn]});
   	   					}
   	                });

   	                //Actualitzem el model amb els valors carregats al llistat specificListFilter
   	                filterSpecificStatusAux.model = $linq(filterSpecificStatusAux.model).where(function(specificStatus){
	        			return $linq(specificListFilter).contains(specificStatus[$scope.languageColumn], function(x,y){
	        				if(x && x.id && y == x.id) { return true; } else { return false; }
	        			});
   	                }).toArray();
   	                
   	                filterCallAux.specificStatus = specificListFilter;
        		} else {
    				filterSpecificStatusAux.model = undefined;
        		}

                return filterCallAux;
            };

            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.tasks.manageTasks') {
                    $rootScope.headButtons = [
                        new HeadButton('btn primary breadDivButton ', undefined, '#dataTableTask','fa-plus', 'global.literals.new_task_title', undefined, 'newTask').setPermissions('create_task')
                    ];
                    $rootScope.subHeadButtons = [new HeadButtonSearch('#tableFilter')];
                    
                    RestService.findByUrl("./api/tasks/countCanceledTask" + "?createdUser=" + $rootScope.LoggedUser.id + "&endedTask=true").then(function(data){
                    	$scope.numCanceledTask = 0;
                    	if (data && data.data > 0) {
                    		$scope.numCanceledTask = data.data;
                    	}
	                    $rootScope.subHeadTabs = [
	                        {state:'annexa.tasks.assignedTasks', type:'breadDivButton', name:'global.literals.tasksAssigned'},
	                        {state:'annexa.tasks.createdTasks', type:'breadDivButton', name:'global.literals.tasksCreated', permissions: ['create_task']},
	                        {state: 'annexa.tasks.manageTasks', type: 'breadDivButton active', name: 'global.literals.manage', permissions: ['manage_tasks']}
	                    ];
                    }).catch(function(){
                    });
                    
                }
            });

            HeaderService.changeState($state.current);

            var daysColumn = new RemaingDaysGlobalColumn($filter, 'global.literals.days', HelperService, $rootScope.LoggedUser.entity.holidays);
            var taskNumberColumn =  new StrippedColumn($filter, 'global.literals.code');
            var taskNameColumn =  new StrippedColumn($filter, 'global.literals.task_name', 100);
            var taskTypeColumn = new DatabaseTranslatedColumn($filter, 'global.literals.taskType', $scope.languageColumn);
            var userStartedColumn = new UserColumn($filter, 'global.literals.created_for');
            var taskCreateDateColumn = new DateColumn($filter, 'global.literals.creation_date');
            var userAssignedColumn = new TaskUserProfileColumn($filter,'global.literals.assigned_to', $scope.languageColumn);
            var taskStartDateColumn = new DateColumn($filter,'global.literals.startDate');
            var taskExpiryDateColumn = new DateColumn($filter,'global.literals.endDate');
            var taskActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                new ActionButton('global.literals.see','annexa.tasks.manageTasks.edit({ task: \'[data]\'})','fa-eye', true), []);

            $scope.columnsAux = [
            	{id: 'status.globalStatus', title: $scope.taskStatusTitle(), render: $scope.taskStatusRender, sortable: false},
                {id: 'remainingDays', width: '40px', column:daysColumn },
                {id: 'taskNumber', column: taskNumberColumn },
                {id: 'description', column: taskNameColumn, className: 'pre-line', sortable: false },
                {id: 'type.' + taskTypeColumn.getColumn(), title: taskTypeColumn.getTitle() },
                {id: 'createdUser', column: userStartedColumn},
                {id: 'createdDate', column: taskCreateDateColumn},
                {id: 'user', column: userAssignedColumn},
 				{id: 'dossierTransactions', column: new DossierTransactionColumn($scope, $filter, 'global.literals.trams','', 'dossierTransactions', 'dossierTransactionsComponent'), sortable: false },
                {id: 'taskComments', width: '100px', column: new CommentColumn($filter, 'global.literals.comments', 'fa-comment', 'comments', 'createdUser', true), sortable: false},
                {id: 'startDate', column: taskStartDateColumn},
                {id: 'endDate', column: taskExpiryDateColumn}
            ];

            var datatableSettings = $rootScope.app.configuration.datatables_settings;
            $scope.columnsManagedTasks = angular.copy($scope.columnsAux);
            $scope.columnsManagedTasks.push({ id: 'actions', columnName: 'id', width: '170px', className: 'text-center', title: taskActionsColumn.getTitle(), render: taskActionsColumn.getRender, sortable: false});
            $scope.columnsManagedTasks = getDatatableColumnsSettings(datatableSettings, 'datatable_task_created', $scope.columnsManagedTasks);

            var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_task_created', $scope.columnsManagedTasks);
            $scope.tableOrderProperties = {sortName: 'remainingDays', sort: [[1,'desc']]};
            if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > 0){
                $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
            }

            $scope.tableDefinition = {
                id: 'tableManagedTasks',
                origin: 'tasks',
                objectType: 'TaskView',
                sortName: $scope.tableOrderProperties.sortName,
                sort: $scope.tableOrderProperties.sort,
                filterCall: $scope.getFilterCall(),
                filterCallAux: $scope.getFilterCallAux(),
                filterCallFunc: $scope.getFilterCall,
                filterCallAuxFunc: $scope.getFilterCallAux,
                columns: angular.copy($scope.columnsManagedTasks),
                containerScope: $scope,
                callOrigin: 'managedTasks'
            }
        }])
    .controller('CreatedTasksController',['$filter','$state','$scope', 'TaskFactory','api', '$stateParams', '$rootScope', 'HeaderService', 'HelperService', 'RouteStateFactory', 'TableFilterFactory', 'RestService', 'GlobalDataFactory',
        function($filter, $state, $scope, TaskFactory, api, $stateParams, $rootScope, HeaderService, HelperService, RouteStateFactory, TableFilterFactory, RestService, GlobalDataFactory) {
            $scope.tfilter = [
            	{ id: 'taskNumber', type: 'text', order: 0, label: 'global.literals.code', preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','taskNumber'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','taskNumber') },
                { id: 'description', type: 'text', order: 1, label: 'global.literals.task_name', preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','description'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','description') },
                { id: 'createdUser', type: 'text', order: 2, label: 'global.literals.started_for', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','createdUser'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','createdUser')},
                { id: 'user', type: 'text', order: 3, label: 'global.literals.assigned_to', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','user'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','user')},
                { id: 'startDates', type: 'dateRange', order: 4, label: 'global.literals.startDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','startDates'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','startDates')},
                { id: 'endDates', type: 'dateRange', order: 5, label: 'global.literals.endDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','endDates'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','endDates')},
                { id: 'expired', type: 'select', order: 6, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: angular.copy($scope.expiredStates), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','expired',$scope.expiredStates,'id'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','expired')},
                { id: 'type', type: 'select', order: 7, label: 'global.literals.type', dataType: 'LOCAL', data: angular.copy(GlobalDataFactory.taskTypes), addAll: true, nameProperty: $scope.languageColumn, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','type',GlobalDataFactory.taskTypes,'id'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','type')},
                { id: 'status', type: 'select-multiple', order: 8, label: 'global.literals.globalStatus', dataType: 'LOCAL', data: angular.copy($scope.taskStatuses), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','status',$scope.taskStatuses,'id'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','status')},
                { id: 'specificStatus', type: 'select-multiple', order: 9, label: 'global.literals.status', dataType: 'LOCAL', data: $scope.getSpecificStatuses(), addAll: true, nameProperty: $scope.languageColumn, callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','specificStatus',$scope.getSpecificStatuses(),'id'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','specificStatus')},
                { id: 'thirdName', type: 'text', order: 10, label: 'global.thirds.literals.third', callAux: true , preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','thirdName'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','thirdName')},
                { id: 'usersName', type: 'text', order: 11, label: 'global.users.list.boxTaskUsersToptitle', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','usersName'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','usersName') },
                { id: 'address', type: 'text', order: 12, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','address'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','address') },
                { id: 'email', type: 'text', order: 13, label: 'global.tasks.literals.email', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','email'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','email') },
                { id: 'phone', type: 'text', order: 14, label: 'global.tasks.literals.phone', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','phone'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','phone') },
                { id: 'meta', type: 'text', order: 15, label: 'global.literals.meta', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','meta'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','meta') },
				{ id: 'dossier', type: 'text', order: 16, label: 'global.literals.dossier', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','dossier'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','dossier') },
                { id: 'subtasks', type: 'checkbox', order: 17, label: 'global.tasks.literals.alsoSubtasks', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','subtasks')},
                { id: 'duplicatedTasks', type: 'checkbox', order: 18, label: 'global.tasks.literals.alsoDuplicatedTasks', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','duplicatedTasks')},
                { id: 'noThirds', type: 'checkbox', order: 19, label: 'global.tasks.literals.noThirds', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','noThirds')},
                { id: 'noAffectedUsers', type: 'checkbox', order: 20, label: 'global.users.list.noAffectedUsers', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','noAffectedUsers')},
                { id: 'allowedCreateExternally', type: 'checkbox', order: 21, label: 'global.tasks.literals.allowedCreateExternally', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','allowedCreateExternally')}
            ];
            var routeState = RouteStateFactory.getRouteState($state.current);
            if(routeState) {
                $scope.tfilter = routeState.state;
            }
            $scope.filterData.assigned = false;
            $scope.filterData.manage = false;           
            $scope.numCanceledTask = 0;

            if($stateParams.type){
                var filterStatus = $linq($scope.tfilter).where("x => x.id == 'status'").toArray();
                var filterExpired= $linq($scope.tfilter).where("x => x.id == 'expired'").toArray();
                if($stateParams.type == 'C' && filterStatus) {
                    filterStatus[0].model = [{ id: 'PENDING', name: $filter('translate')('PENDING') }, { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') }];
                }else if($stateParams.type == 'CE' && filterStatus && filterExpired){
                    filterExpired[0].model = { id: 'expired', name: $filter('translate')('global.literals.outOfTime') };
                    filterStatus[0].model = [{ id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') }];
                }else if($stateParams.type == 'CNE' && filterStatus && filterExpired){
                    filterExpired[0].model = { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') };
                    filterStatus[0].model = [{ id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') }];
                }else if($stateParams.type == 'CP' && filterStatus){
                    filterStatus[0].model = [{ id: 'PENDING', name: $filter('translate')('PENDING') }];
                }else if($stateParams.type == 'CF' && filterStatus){
                    filterStatus[0].model = [{ id: 'COMPLETED', name: $filter('translate')('COMPLETED') }];
                }
            }
            $scope.getFilterCall = function() {
                var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
                return filterCall;
            };

            $scope.getFilterCallAux = function(){
                var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
                filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                filterCallAux.assigned = $scope.filterData.assigned;
                filterCallAux.manage = $scope.filterData.manage;

                if(filterCallAux.expired){
                    if(filterCallAux.expired.id == 'expired'){
                        filterCallAux.expired = true;
                    }else if(filterCallAux.expired.id == 'noExpired'){
                        filterCallAux.expired = false;
                    }
                }
                
        		if($scope.changeStatusFromHeader){
            		var filterAux = $linq($scope.tfilter).singleOrDefault(undefined, "x => x.id == 'status'");
            		if($scope.filterData.status){
            			if(filterAux){
                			var status = $linq($scope.taskStatuses).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.status + "'");
                			filterCallAux.status = [status];
               				filterAux.model = filterCallAux.status;
                		}else{
                			filterCallAux.status = undefined;
               		    	filterAux.model = undefined;
                		}
                	}else{
                		filterCallAux.status = undefined;
            		    if(filterAux){
            		    	filterAux.model = undefined;
            		    }
            		}
            		$scope.changeStatusFromHeader = false;
            	}else if(filterCallAux.status && filterCallAux.status.length == 1){
            		if(filterCallAux.status[0].id != $scope.filterData.status){
            			$scope.filterData.status = filterCallAux.status[0].id;
            		}
            	}else if(!filterCallAux.status || filterCallAux.status.length != 1){
            		$scope.filterData.status = undefined;
            	}

        		var filterSpecificStatusAux = $linq($scope.tfilter).singleOrDefault(undefined, "x => x.id == 'specificStatus'");
   				filterSpecificStatusAux.data = $scope.getSpecificStatuses(filterCallAux.status);

   				if(filterCallAux.specificStatus && filterCallAux.specificStatus.length > 0){
   					var specificListFilter = [];
   	                _.forEach(filterCallAux.specificStatus, function(value, index) {
   	   					if($linq(filterSpecificStatusAux.data).contains(value, function(x,y){
   	   						if(x && x.id && y.id == x.id) { return true; } else { return false; } 
   	   					})) {
   	   						specificListFilter.push({id: value[$scope.languageColumn]});
   	   					}
   	                });

   	                //Actualitzem el model amb els valors carregats al llistat specificListFilter
   	                filterSpecificStatusAux.model = $linq(filterSpecificStatusAux.model).where(function(specificStatus){
	        			return $linq(specificListFilter).contains(specificStatus[$scope.languageColumn], function(x,y){
	        				if(x && x.id && y == x.id) { return true; } else { return false; }
	        			});
   	                }).toArray();
   	                
   	                filterCallAux.specificStatus = specificListFilter;
        		} else {
    				filterSpecificStatusAux.model = undefined;
        		}

            	return filterCallAux;
            };

            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.tasks.createdTasks') {
                    $rootScope.headButtons = [
                        new HeadButton('btn primary breadDivButton ', undefined, '#dataTableTask','fa-plus', 'global.literals.new_task_title', undefined, 'newTask').setPermissions('create_task')
                    ];
                    $rootScope.subHeadButtons = [new HeadButtonSearch('#tableFilter')];
                    
                    RestService.findByUrl("./api/tasks/countCanceledTask" + "?createdUser=" + $rootScope.LoggedUser.id + "&endedTask=true").then(function(data){
                    	$scope.numCanceledTask = 0;
                    	if (data && data.data > 0) {
                    		$scope.numCanceledTask = data.data;
                    	}
	                    $rootScope.subHeadTabs = [
	                        {state:'annexa.tasks.assignedTasks', type:'breadDivButton', name:'global.literals.tasksAssigned'},
	                        {state:'annexa.tasks.createdTasks', type:'breadDivButton active', name:'global.literals.tasksCreated', permissions: ['create_task']},
	                        {state: 'annexa.tasks.manageTasks', type: 'breadDivButton', name: 'global.literals.manage', permissions: ['manage_tasks']}
	                    ];
                    }).catch(function(){
                    });
                    
                }
            });

            HeaderService.changeState($state.current);

            var daysColumn = new RemaingDaysGlobalColumn($filter, 'global.literals.days', HelperService, $rootScope.LoggedUser.entity.holidays);
            var taskNumberColumn =  new StrippedColumn($filter, 'global.literals.code');
            var taskNameColumn =  new StrippedColumn($filter, 'global.literals.task_name', 100);
            var taskTypeColumn = new DatabaseTranslatedColumn($filter, 'global.literals.taskType', $scope.languageColumn);
            var userStartedColumn = new UserColumn($filter, 'global.literals.created_for');
            var taskCreateDateColumn = new DateColumn($filter, 'global.literals.creation_date');
            var userAssignedColumn = new TaskUserProfileColumn($filter,'global.literals.assigned_to', $scope.languageColumn);
            var taskStartDateColumn = new DateColumn($filter,'global.literals.startDate');
            var taskExpiryDateColumn = new DateColumn($filter,'global.literals.endDate');
            var taskActionsColumn =	new ActionsColumn($filter('translate')('global.literals.actions'),
            		new ActionButton('global.literals.see','annexa.tasks.createdTasks.edit({ task: \'[data]\'})','fa-eye', true), [
            		new ActionButton('global.literals.reassignTask', 'reassignTask([data])', undefined, undefined, function (data, type, full, meta) {
        				if(!full.endedTask && !full.endDate){
        					return true;
        				} else {
        					return false;
        				}
        			})]
            	);
            
            $scope.columnsAux = [
            	{id: 'status.globalStatus', title: $scope.taskStatusTitle(), render: $scope.taskStatusRender, sortable: false},
                {id: 'remainingDays', width: '40px', column:daysColumn },
                {id: 'taskNumber', column: taskNumberColumn },
                {id: 'description', column: taskNameColumn, className: 'pre-line', sortable: false },
                {id: 'type.' + taskTypeColumn.getColumn(), title: taskTypeColumn.getTitle() },
                {id: 'createdUser', column: userStartedColumn},
                {id: 'createdDate', column: taskCreateDateColumn},
                {id: 'user', column: userAssignedColumn},
                {id: 'dossierTransactions', column: new DossierTransactionColumn($scope, $filter, 'global.literals.trams','', 'dossierTransactions', 'dossierTransactionsComponent'), sortable: false },
                {id: 'taskComments', width: '100px', column: new CommentColumn($filter, 'global.literals.comments', 'fa-comment', 'comments', 'createdUser', true), sortable: false},
                {id: 'startDate', column: taskStartDateColumn},
                {id: 'endDate', column: taskExpiryDateColumn}
            ];

            var datatableSettings = $rootScope.app.configuration.datatables_settings;
            $scope.columnsCreatedTasks = angular.copy($scope.columnsAux);
            $scope.columnsCreatedTasks.push({ id: 'actions', columnName: 'id', width: '170px', className: 'text-center', title: taskActionsColumn.getTitle(), render: taskActionsColumn.getRender, sortable: false});
            $scope.columnsCreatedTasks = getDatatableColumnsSettings(datatableSettings, 'datatable_task_created', $scope.columnsCreatedTasks);

            var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_task_created', $scope.columnsCreatedTasks);
            $scope.tableOrderProperties = {sortName: 'remainingDays', sort: [[1,'desc']]};
            if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > 0){
                $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
            }

            $scope.tableDefinition = {
                id: 'tableCreatedTasks',
                origin: 'tasks',
                objectType: 'TaskView',
                sortName: $scope.tableOrderProperties.sortName,
                sort: $scope.tableOrderProperties.sort,
                filterCall: $scope.getFilterCall(),
                filterCallAux: $scope.getFilterCallAux(),
                filterCallFunc: $scope.getFilterCall,
                filterCallAuxFunc: $scope.getFilterCallAux,
                columns: angular.copy($scope.columnsCreatedTasks),
                containerScope: $scope,
                callOrigin: 'createdTasks'
            }

    }])
    .controller('AssignedTasksController',['$filter', '$state', '$scope','HeaderService', 'TaskFactory', 'api', '$stateParams', '$rootScope', 'HelperService', 'RouteStateFactory', 'TableFilterFactory', 'RestService', 'GlobalDataFactory',
        function($filter, $state, $scope, HeaderService, TaskFactory, api, $stateParams, $rootScope, HelperService, RouteStateFactory, TableFilterFactory, RestService, GlobalDataFactory) {
            $scope.filterData.assigned = true;
            $scope.filterData.manage = false;
            $scope.numCanceledTask = 0;
            
            $scope.tfilter = [
            	{ id: 'taskNumber', type: 'text', order: 0, label: 'global.literals.code', preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','taskNumber'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','taskNumber') },
                { id: 'description', type: 'text', order: 1, label: 'global.literals.task_name', preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','description'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','description') },
                { id: 'createdUser', type: 'text', order: 2, label: 'global.literals.started_for', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','createdUser'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','createdUser')},
                { id: 'user', type: 'text', order: 3, label: 'global.literals.assigned_to', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','user'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','user')},
                { id: 'startDates', type: 'dateRange', order: 4, label: 'global.literals.startDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','startDates'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','startDates')},
                { id: 'endDates', type: 'dateRange', order: 5, label: 'global.literals.endDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','endDates'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','endDates')},
                { id: 'expired', type: 'select', order: 6, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: angular.copy($scope.expiredStates), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','expired',$scope.expiredStates,'id'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','expired')},
                { id: 'type', type: 'select', order: 7, label: 'global.literals.type', dataType: 'LOCAL', data: angular.copy(GlobalDataFactory.taskTypes), addAll: true, nameProperty: $scope.languageColumn, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','type',GlobalDataFactory.taskTypes,'id'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','type')},
                { id: 'status', type: 'select-multiple', order: 8, label: 'global.literals.globalStatus', dataType: 'LOCAL', data: angular.copy($scope.taskStatuses), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','status',$scope.taskStatuses,'id'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','status')},
                { id: 'specificStatus', type: 'select-multiple', order: 9, label: 'global.literals.status', dataType: 'LOCAL', data: $scope.getSpecificStatuses(), addAll: true, nameProperty: $scope.languageColumn, callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','specificStatus',$scope.getSpecificStatuses(),'id'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','specificStatus')},
                { id: 'thirdName', type: 'text', order: 10, label: 'global.thirds.literals.third', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','thirdName'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','thirdName') },                
                { id: 'usersName', type: 'text', order: 11, label: 'global.users.list.boxTaskUsersToptitle', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','usersName'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','usersName') },                
                { id: 'address', type: 'text', order: 12, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','address'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','address') },                
                { id: 'email', type: 'text', order: 13, label: 'global.tasks.literals.email', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','email'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','email') }, 
                { id: 'phone', type: 'text', order: 14, label: 'global.tasks.literals.phone', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','phone'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','phone') },
                { id: 'meta', type: 'text', order: 15, label: 'global.literals.meta', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','meta'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','meta') },
				{ id: 'dossier', type: 'text', order: 16, label: 'global.literals.dossier', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','dossier'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','dossier') },
                { id: 'subtasks', type: 'checkbox', order: 17, label: 'global.tasks.literals.alsoSubtasks', callAux: true, preFilter: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','subtasks')},
                { id: 'duplicatedTasks', type: 'checkbox', order: 18, label: 'global.tasks.literals.alsoDuplicatedTasks', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','duplicatedTasks')},
                { id: 'noThirds', type: 'checkbox', order: 19, label: 'global.tasks.literals.noThirds', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','noThirds')},
                { id: 'noAffectedUsers', type: 'checkbox', order: 20, label: 'global.users.list.noAffectedUsers', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','noAffectedUsers')},
                { id: 'allowedCreateExternally', type: 'checkbox', order: 21, label: 'global.tasks.literals.allowedCreateExternally', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','allowedCreateExternally')}
                
            ];

            var routeState = RouteStateFactory.getRouteState($state.current);
            if(routeState) {
                $scope.tfilter = routeState.state;
            }
            if($stateParams.type){
                var filterStatus = $linq($scope.tfilter).where("x => x.id == 'status'").toArray();
                var filterExpired= $linq($scope.tfilter).where("x => x.id == 'expired'").toArray();
                if($stateParams.type == 'A' && filterStatus) {
                    filterStatus[0].model = [{ id: 'PENDING', name: $filter('translate')('PENDING') }, { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') }];
                }else if($stateParams.type == 'AE' && filterStatus && filterExpired){
                    filterExpired[0].model = { id: 'expired', name: $filter('translate')('global.literals.outOfTime') };
                    filterStatus[0].model = [{ id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') }];
                }else if($stateParams.type == 'ANE' && filterStatus && filterExpired){
                    filterExpired[0].model = { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') };
                    filterStatus[0].model = [{ id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') }];
                }else if($stateParams.type == 'AP' && filterStatus){
                    filterStatus[0].model = [{ id: 'PENDING', name: $filter('translate')('PENDING') }];
                }else if($stateParams.type == 'AF' && filterStatus){
                    filterStatus[0].model = [{ id: 'COMPLETED', name: $filter('translate')('COMPLETED') }];
                }
            }

            $scope.getFilterCall = function() {
                var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
                return filterCall;
            };

            $scope.getFilterCallAux = function(){
                var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
                filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                filterCallAux.assigned = $scope.filterData.assigned;
                filterCallAux.manage = $scope.filterData.manage;

                if(filterCallAux.expired){
                    if(filterCallAux.expired.id == 'expired'){
                        filterCallAux.expired = true;
                    }else if(filterCallAux.expired.id == 'noExpired'){
                        filterCallAux.expired = false;
                    }else{
                        filterCallAux.expired = undefined;
                    }
                }
                
        		if($scope.changeStatusFromHeader){
            		var filterAux = $linq($scope.tfilter).singleOrDefault(undefined, "x => x.id == 'status'");
            		if($scope.filterData.status){
            			if(filterAux){
                			var status = $linq($scope.taskStatuses).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.status + "'");
                			filterCallAux.status = [status];
               				filterAux.model = filterCallAux.status;
                		}else{
                			filterCallAux.status = undefined;
               		    	filterAux.model = undefined;
                		}
                	}else{
                		filterCallAux.status = undefined;
            		    if(filterAux){
            		    	filterAux.model = undefined;
            		    }
            		}
            		$scope.changeStatusFromHeader = false;
            	}else if(filterCallAux.status && filterCallAux.status.length == 1){
            		if(filterCallAux.status[0].id != $scope.filterData.status){
            			$scope.filterData.status = filterCallAux.status[0].id;
            		}
            	}else if(!filterCallAux.status || filterCallAux.status.length != 1){
            		$scope.filterData.status = undefined;
            	}

        		var filterSpecificStatusAux = $linq($scope.tfilter).singleOrDefault(undefined, "x => x.id == 'specificStatus'");
   				filterSpecificStatusAux.data = $scope.getSpecificStatuses(filterCallAux.status);

   				if(filterCallAux.specificStatus && filterCallAux.specificStatus.length > 0){
   					var specificListFilter = [];
   	                _.forEach(filterCallAux.specificStatus, function(value, index) {
   	   					if($linq(filterSpecificStatusAux.data).contains(value, function(x,y){
   	   						if(x && x.id && y.id == x.id) { return true; } else { return false; } 
   	   					})) {
   	   						specificListFilter.push({id: value[$scope.languageColumn]});
   	   					}
   	                });

   	                //Actualitzem el model amb els valors carregats al llistat specificListFilter
   	                filterSpecificStatusAux.model = $linq(filterSpecificStatusAux.model).where(function(specificStatus){
	        			return $linq(specificListFilter).contains(specificStatus[$scope.languageColumn], function(x,y){
	        				if(x && x.id && y == x.id) { return true; } else { return false; }
	        			});
   	                }).toArray();
   	                
   	                filterCallAux.specificStatus = specificListFilter;
        		} else {
    				filterSpecificStatusAux.model = undefined;
        		}
        		
            	return filterCallAux;
            };

            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.tasks.assignedTasks') {
                    $rootScope.headButtons = [
                     new HeadButton('btn primary breadDivButton mb-xs-pt', undefined, '#dataTableTask','fa-plus', 'global.literals.new_task_title', undefined, 'newTask').setPermissions('create_task')
                     ];
                    $rootScope.subHeadButtons = [new HeadButtonSearch('#tableFilter')];
                    
                    RestService.findByUrl("./api/tasks/countCanceledTask" + "?createdUser=" + $rootScope.LoggedUser.id + "&endedTask=true").then(function(data){
                    	$scope.numCanceledTask = 0;
                    	if (data && data.data > 0) {
                    		$scope.numCanceledTask = data.data;
                    	}
	                    $rootScope.subHeadTabs = [
	                        {state:'annexa.tasks.assignedTasks', type:'breadDivButton active', name:'global.literals.tasksAssigned'},
	                        {state:'annexa.tasks.createdTasks', type:'breadDivButton', name:'global.literals.tasksCreated', permissions: ['create_task']},
	                        {state: 'annexa.tasks.manageTasks', type: 'breadDivButton', name: 'global.literals.manage', permissions: ['manage_tasks']}
	                    ];
                    }).catch(function(){
                    });  
                    
                }
            });

            HeaderService.changeState($state.current);

            var daysColumn = new RemaingDaysGlobalColumn($filter, 'global.literals.days', HelperService, $rootScope.LoggedUser.entity.holidays);
            var taskNumberColumn =  new StrippedColumn($filter, 'global.literals.code');
            var taskNameColumn =  new StrippedColumn($filter, 'global.literals.task_name', 100);
            var taskTypeColumn = new DatabaseTranslatedColumn($filter, 'global.literals.taskType', $scope.languageColumn);
            var userStartedColumn = new UserColumn($filter, 'global.literals.created_for');
            var taskCreateDateColumn = new DateColumn($filter, 'global.literals.creation_date');
            var userAssignedColumn = new TaskUserProfileColumn($filter,'global.literals.assigned_to', $scope.languageColumn);
            var taskStartDateColumn = new DateColumn($filter,'global.literals.startDate');
            var taskExpiryDateColumn = new DateColumn($filter,'global.literals.endDate');
            var taskActionsColumn =	new ActionsColumn($filter('translate')('global.literals.actions'),
            		new ActionButton('global.literals.see','annexa.tasks.assignedTasks.edit({ task: \'[data]\'})','fa-eye', true), [
            		new ActionButton('global.literals.reassignTask', 'reassignTask([data])', undefined, undefined, function (data, type, full, meta) {
        				if(!full.endedTask && !full.endDate){
        					return true;
        				} else {
        					return false;
        				}
        			})]
            	);

            $scope.columnsAux = [
            	{id: 'status.globalStatus', title: $scope.taskStatusTitle(), render: $scope.taskStatusRender, sortable: false},
                {id: 'remainingDays', width: '40px', column:daysColumn },
                {id: 'taskNumber', column: taskNumberColumn },
                {id: 'description', column: taskNameColumn, className: 'pre-line', sortable: false },
                {id: 'type.' + taskTypeColumn.getColumn(), title: taskTypeColumn.getTitle() },
                {id: 'createdUser', column: userStartedColumn},
                {id: 'createdDate', column: taskCreateDateColumn},
                {id: 'user', column: userAssignedColumn},
                {id: 'dossierTransactions', column: new DossierTransactionColumn($scope, $filter, 'global.literals.trams','', 'dossierTransactions', 'dossierTransactionsComponent'), sortable: false },
                {id: 'taskComments', width: '100px', column: new CommentColumn($filter, 'global.literals.comments', 'fa-comment', 'comments', 'createdUser', true), sortable: false},
                {id: 'startDate', column: taskStartDateColumn},
                {id: 'endDate', column: taskExpiryDateColumn}
            ];

            var datatableSettings = $rootScope.app.configuration.datatables_settings;
            $scope.columnsAssignedTasks = angular.copy($scope.columnsAux);
            $scope.columnsAssignedTasks.push({ id: 'actions', columnName: 'id', width: '170px', className: 'text-center', title: taskActionsColumn.getTitle(), render: taskActionsColumn.getRender, sortable: false});
            $scope.columnsAssignedTasks = getDatatableColumnsSettings(datatableSettings, 'datatable_tasks_assigned', $scope.columnsAssignedTasks);

            var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_tasks_assigned', $scope.columnsAssignedTasks);
            $scope.tableOrderProperties = {sortName: 'remainingDays', sort: [[1,'desc']]};
            if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > 0){
                $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
            }

            $scope.tableDefinition = {
                id: 'tableAssignedTasks',
                origin: 'tasks',
                objectType: 'TaskView',
                sortName: $scope.tableOrderProperties.sortName,
                sort: $scope.tableOrderProperties.sort,
                filterCall: $scope.getFilterCall(),
                filterCallAux: $scope.getFilterCallAux(),
                filterCallFunc: $scope.getFilterCall,
                filterCallAuxFunc: $scope.getFilterCallAux,
                columns: angular.copy($scope.columnsAssignedTasks),
                containerScope: $scope,
                callOrigin: 'assignedTasks'
            }

    }])
    .controller('NewTasksController',['$filter', '$state', '$scope','HeaderService', 'AnnexaEntityFactory', '$rootScope', function($filter, $state, $scope, HeaderService, AnnexaEntityFactory, $rootScope) {
        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.tasks.createdTasks.new') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn grey-500 text-white breadDivButton m-r-sm', undefined, '#new-task',undefined,'global.literals.cancel',undefined,'cancelEntity'),
                    new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#new-task','fa-floppy-o','global.literals.save',undefined,'saveEntity')
                ];
            }
        });

        HeaderService.changeState($state.current);
        $scope.entity = AnnexaEntityFactory.getNewTaskForm();
    }])
    .controller('EditTasksController',['$q','$filter', '$state', '$scope','HeaderService', 'TaskFactory', 'AnnexaEntityFactory', 'api', '$stateParams', '$rootScope', 'AnnexaFormlyFactory', 'TasksModals', 'HelperService', 'GlobalDataFactory', 'Language', 'TerritoryFactory', 'CustomFieldFactory', 'DialogsFactory', 'TableFilter', 'RestService', 'DTColumnBuilder', 'AnnexaModalFactory',
        function($q, $filter, $state, $scope, HeaderService, TaskFactory, AnnexaEntityFactory, api, $stateParams, $rootScope, AnnexaFormlyFactory, TasksModals, HelperService, GlobalDataFactory, Language, TerritoryFactory, CustomFieldFactory, DialogsFactory, TableFilter, RestService, DTColumnBuilder, AnnexaModalFactory) {
            $scope.task = TaskFactory.task;
            $scope.userAux = TaskFactory.userAux;
            $scope.createdUserAux = TaskFactory.createdUserAux;
            $scope.isCreator = TaskFactory.isCreator;
            $scope.isAssigned = TaskFactory.isAssigned;
            $scope.esetMode = $rootScope.esetMode;
            $scope.isSubtask = TaskFactory.isSubtask;
            $scope.isDuplicatedTask = TaskFactory.isDuplicatedTask;

            $scope.languageColumn = Language.getActiveColumn();
            $scope.taskTypes = GlobalDataFactory.taskTypes;
            $scope.taskOrigins = GlobalDataFactory.taskOrigins;

            $scope.assignToOptions = [{id: 'user', description: $filter('translate')('global.literals.user')}, {id: 'profile', description: $filter('translate')('global.literals.profile')}];
            $scope.assignTo = $scope.task.user ? 'user' : 'profile';
            
            $scope.selectAssignTo = function(value) {
            	$scope.assignTo = value;
            }
            
            
            var selectableOptions = [];
            _.forEach(GlobalDataFactory.allProfiles, function (value, key) {
                if(value.expiryDate == null || ($scope.task.profile && $scope.task.profile.id == value.id)) {
                    selectableOptions.push(value);
                }
            });
            $scope.allProfiles = new SortedArray(selectableOptions, $scope.languageColumn).sort();
            
            $scope.printProfile = function (modelValue) {
            	if (modelValue) {
            		return modelValue[$scope.languageColumn];	
            	} else {
            		return '';
            	}
            }

            $scope.printParentTask = function (modelValue) {
            	if (modelValue) {
            		return Array.isArray(modelValue) ? modelValue[0].parentTask.description : modelValue.description;	
            	} else {
            		return '';
            	}
            }
            
            $scope.updateTaskStatuses = function(taskType) {
            	if (taskType && taskType.id && $scope.task.type && $scope.task.type.id && taskType.id != $scope.task.type.id) {
                	$scope.task.status = undefined;
            		$scope.task.type = taskType;
            	}
            	$scope.taskStatusesEdit = [];

            	if (taskType.statuses) {
        			$scope.taskStatusesEdit = taskType.statuses;
        		}
        		if (taskType.parent && taskType.parent.statuses) {
        			$scope.taskStatusesEdit = $scope.taskStatusesEdit.concat(taskType.parent.statuses);
        			$scope.taskStatusesEdit = $linq($scope.taskStatusesEdit).orderBy("x => x." + $scope.languageColumn, linq.caseInsensitiveComparer).toArray();
        		}
            }
            
            $scope.updateTaskStatuses($scope.task.type);
            
            $scope.printTaskType = function (modelValue) {
            	return modelValue[$scope.languageColumn];
            }

            $scope.printTaskStatus = function (modelValue) {
            	if (modelValue) {
            		return modelValue[$scope.languageColumn];	
            	} else {
            		return '';
            	}
            }

            $scope.printTaskOrigin = function (modelValue) {
            	if (modelValue) {
            		return modelValue[$scope.languageColumn];	
            	} else {
            		return '';
            	}
            }

            $scope.updateCustomField = function (field) {
                var model = {
                    task: { id: $scope.task.id },
                    customField: { id: field.customField.id },
                    required: field.required,
                    viewOrder: field.viewOrder,
                    value: field.value,
                    id: field.id
                };

                TaskFactory.updateTaskCustomField(model)
                    .then(function(data) {
                        //Empty
                    }).catch(function(error) {
                        //Empty
                });
            };

            $scope.thirdModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.thirds'").toArray();
            $scope.docModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.doc.documents.all'").toArray();
            $scope.dossierModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tram'").toArray();
            $scope.regInputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.input'").toArray();
            $scope.regOutputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.output'").toArray();


            if($rootScope.esetMode) {
                if($rootScope.LoggedUser && $rootScope.LoggedUser.entity && $rootScope.LoggedUser.entity.holidays){
                    $scope.task.taskEndDate = HelperService.getExpirationDate(new Date($scope.task.createdDate),$scope.task.days, $rootScope.LoggedUser.entity.holidays);
                }else{
                    $scope.task.taskEndDate = HelperService.getExpirationDate(new Date($scope.task.createdDate),$scope.task.days, undefined);
                }
            }

            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.tasks.createdTasks.edit' || message.state.name == 'annexa.tasks.assignedTasks.edit') {
                    $rootScope.subHeadButtons = [];

                    var taskOpened = false;

                    if($scope.task && !$scope.task.endedTask && !$scope.task.endDate && ($scope.isAssigned || $scope.isCreator)) {
                    	taskOpened = true;
                    }

                    if(taskOpened && !$scope.isDuplicatedTask) {
                        $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-task', 'fa fa-exchange', 'global.literals.reassignTask', undefined, 'reassignTaskEdit'));
                    }
                    
                    if ($scope.isAssigned || $scope.isCreator) {
                    	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-task', 'fa fa-pencil', 'global.literals.changeStateTask', undefined, 'changeStateTaskEdit'));
                    }
                    
                    if(taskOpened && !$scope.isDuplicatedTask) {
                    	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-task', 'fa fa-pencil', 'global.tasks.literals.modifyTaskType', undefined, 'modifyTaskType'));
                    }

                    if(($scope.isAssigned || $scope.isCreator) /*&& !$scope.isDuplicatedTask && !$scope.isSubtask*/) {
                        $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-task', 'fa fa-link', 'global.tasks.literals.linkParentTask', undefined, 'linkParentTask'));
                    }
                    
                    $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-task', 'fa fa-eye', 'global.documents.new.audit', undefined, 'auditTask').setPermissions('admin_audit'));
                }
            });

            HeaderService.changeState($state.current);
            var isOpen = true;

            $scope.isManage = false;
            if($state.current.name == 'annexa.tasks.manageTasks.edit') {
                $scope.isManage = true;
            }

            var addTaskSave = function(newTasks) {
                var tasks = [];
                if(TaskFactory.task && TaskFactory.task.relatedTasks) {
                    tasks = TaskFactory.task.relatedTasks;
                }

            	_.forEach(newTasks, function(newTask){
            		tasks.push({task: TaskFactory.task, relatedTask: newTask});
        		});
                
                TaskFactory.updateTask(TaskFactory.task).then(function (data) {
                	var dataAux = JSOG.decode(data);
                	TaskFactory.task.relatedTasks = dataAux.relatedTasks;
                	$scope.taskBox.content.length = 0;
                	
                    if(dataAux.relatedTasks){
                        _.forEach(dataAux.relatedTasks, function (value) {
                            var taskAux = angular.copy(value.relatedTaskInit);
                            taskAux.idOriginal = value.id;
                            $scope.taskBox.content.push(taskAux);
                        });
                    }
                }).catch(function (error) {
                	console.log(error);
                });
            }
            
            var addParentTaskSave = function(newTasks, type) {
        		var tasks = [];
				if(TaskFactory.task && TaskFactory.task.subTasks) {
					tasks = TaskFactory.task.subTasks;
				}

				var confirmedTasks = [];
				
            	_.forEach(newTasks, function(subtask){
                	if (subtask.parentTask) {
                		confirmedTasks.push(subtask);
                	} else {
                        tasks.push({task: subtask, parentTask: TaskFactory.task, managed: type == 'subtask' ? true : false});
                	}
        		});

				if (confirmedTasks.length > 0) {
					var promises = [];
					
                	_.forEach(confirmedTasks, function(subtask){
                		//tractem les tasques que hem confirmat
                    	var indexOfOldTPTask = $linq(subtask.parentTask.subTasks).indexOf("x => x.task.id == " + subtask.id);
                    	var promise = TaskFactory.deleteTaskParentTask(subtask.parentTask.id, subtask.parentTask.subTasks[indexOfOldTPTask].id).then(function () {
                        	if (indexOfOldTPTask != -1) {
								TaskFactory.task.subTasks.push({task: subtask, parentTask: TaskFactory.task, managed: type == 'subtask' ? true : false});
                        	}
                    	}).catch(function (error) {
                        	console.log(error);
                        });
                    	
                    	promises.push(promise);
            		});
                	
                    // wait all promises and resolve
                    $q.all(promises).then(function () {
						var newSubtasks = $linq(TaskFactory.task.subTasks).where("x => x.id == null").toArray();
						var updatedSubtasks = $linq(TaskFactory.task.subTasks).where("x => x.id != null").except(newSubtasks, "(x, y) => x.task.id == y.task.id").toArray();
						TaskFactory.task.subTasks = updatedSubtasks.concat(newSubtasks);
						
                		TaskFactory.updateTask(TaskFactory.task).then(function (dataTask) {
                    		if (dataTask && dataTask.subTasks) {
            					var updatedTask = JSOG.decode(dataTask);
                            	TaskFactory.task.subTasks = updatedTask.subTasks;
            					$rootScope.$broadcast('updateSubtaskBoxesTask', { task: updatedTask });
                    		}
            			}).catch(function (error) {
            				console.log(error);
            			});
                    });
            	} else {
            		//si no hi havia tasques confirmades
    				TaskFactory.updateTask(TaskFactory.task).then(function (data) {
                    	TaskFactory.task.subTasks = JSOG.decode(data).subTasks;
    				}).catch(function (error) {
    					console.log(error);
    				});
            	}
            }
            
            var addUsersSave = function(newUsers) {
        		var users = [];
    			if(TaskFactory.task && TaskFactory.task.users) {
    				users = TaskFactory.task.users;
    			}
    			
    			_.forEach(newUsers, function(newUser){
    				users.push({task: TaskFactory.task, user: newUser.user});
    			});

                TaskFactory.updateTask(TaskFactory.task).then(function(data) {
                	TaskFactory.task.users = JSOG.decode(data).users;
                	$scope.usersBox.content.length = 0;
                	var dataAux = JSOG.decode(data);
                	_.forEach(dataAux.users, function(user){
                		$scope.usersBox.content.push(user);
                	});
                }).catch(function (error) {
                	console.log(error);
                });
            };

    		var addSubtaskSave = function(newTasks) {
            	addParentTaskSave(newTasks, 'subtask');
            }

            var addDuplicatedTaskSave = function(newTasks) {
            	addParentTaskSave(newTasks, 'duplicated');
            }

            var addAddressSave = function(newAddresses) {
        		var addresses = [];
    			if(TaskFactory.task && TaskFactory.task.addresses) {
    				addresses = TaskFactory.task.addresses;
    			}
    			
    			_.forEach(newAddresses, function(newAddress){
    				addresses.push({task: TaskFactory.task, address: newAddress.address});
    			});

                TaskFactory.updateTask(TaskFactory.task).then(function(data) {
                	TaskFactory.task.addresses = JSOG.decode(data).addresses;
                	$scope.addressBox.content.length = 0;
                	var dataAux = JSOG.decode(data);
                	_.forEach(dataAux.addresses, function(address){
                		$scope.addressBox.content.push(address);
                	});
                }).catch(function (error) {
                	console.log(error);
                });
            };
             
            $scope.dossierTransactionBox = AnnexaEntityFactory.getEditTaskBox('dossierTransaction', isOpen && !$scope.isManage && !$scope.isDuplicatedTask);
            $scope.dossierTransactionBox.boxDefinition.isManage = $scope.isManage;
            $scope.dossierTransactionBox.boxDefinition.isDuplicatedTask = $scope.isDuplicatedTask;
            $scope.dossierTransactionBox.boxDefinition.isOpenTask = isOpen;
            $scope.registerInputBox = AnnexaEntityFactory.getEditTaskBox('registerEntry', isOpen && !$scope.isManage && !$scope.isDuplicatedTask, 'INPUT');
            $scope.registerInputBox.boxDefinition.isManage = $scope.isManage;
            $scope.registerInputBox.boxDefinition.isDuplicatedTask = $scope.isDuplicatedTask;
            $scope.registerInputBox.boxDefinition.isOpenTask = isOpen;
            $scope.registerInputBox.boxDefinition.languageColumn = $scope.languageColumn;
            $scope.registerOutputBox = AnnexaEntityFactory.getEditTaskBox('registerEntry', isOpen && !$scope.isManage && !$scope.isDuplicatedTask, 'OUTPUT');
            $scope.registerOutputBox.boxDefinition.isManage = $scope.isManage;
            $scope.registerOutputBox.boxDefinition.isDuplicatedTask = $scope.isDuplicatedTask;
            $scope.registerOutputBox.boxDefinition.isOpenTask = isOpen;
            $scope.registerOutputBox.boxDefinition.languageColumn = $scope.languageColumn;
            $scope.documentBox = AnnexaEntityFactory.getEditTaskBox('document', isOpen && !$scope.isManage && !$scope.isDuplicatedTask);
            $scope.documentBox.boxDefinition.isManage = $scope.isManage;
            $scope.documentBox.boxDefinition.isDuplicatedTask = $scope.isDuplicatedTask;
            $scope.documentBox.boxDefinition.isOpenTask = isOpen;
            $scope.commentBox = AnnexaEntityFactory.getEditTaskBox('comment', isOpen && !$scope.isManage && !$scope.isDuplicatedTask);
            $scope.commentBox.boxDefinition.isManage = $scope.isManage;
            $scope.commentBox.boxDefinition.isDuplicatedTask = $scope.isDuplicatedTask;
            $scope.commentBox.boxDefinition.isOpenTask = isOpen;
            $scope.attachmentBox = AnnexaEntityFactory.getEditTaskBox('attachment', isOpen && !$scope.isManage && !$scope.isDuplicatedTask);
            $scope.attachmentBox.boxDefinition.isManage = $scope.isManage;
            $scope.attachmentBox.boxDefinition.isDuplicatedTask = $scope.isDuplicatedTask;
            $scope.attachmentBox.boxDefinition.isOpenTask = isOpen;

            $scope.taskBox = {
            	boxTitle: 'global.tasks.literals.relatedTasks',
            	permissions: (isOpen && !$scope.isManage && !$scope.isDuplicatedTask) ? { new: ['create_task'], view: ['create_task'] } : {},
                search: { placeholder: 'global.literals.placeholderSearchTaskBox', advanced: true, saveSearch: addTaskSave },
                content: [],
                new: {},
                isEdit: true,
                config: {},
                origin: 'task-task'
            }

            $scope.thirdBox = AnnexaEntityFactory.getEditTaskBox('third', isOpen && !$scope.isManage && !$scope.isDuplicatedTask);
            $scope.thirdBox.boxDefinition.isManage = $scope.isManage;
            $scope.thirdBox.boxDefinition.isDuplicatedTask = $scope.isDuplicatedTask;
            $scope.thirdBox.boxDefinition.isOpenTask = isOpen;
            
            $scope.usersBox = {
            	boxTitle: 'global.users.list.boxTaskUsersToptitle', //boxTitle es opcional, si no se informa utiliza el valor por defecto global.users.list.boxUsersDefaultToptitle
            	permissions: (isOpen && !$scope.isManage && !$scope.isDuplicatedTask) ? { new: ['create_task'], view: ['create_task'] } : {},
            	search: { 
            		placeholder: 'global.literals.placeholderSearchUsersBox', 
            		advanced: true,
            		additionalFilter: { searchBox: true },
            		saveSearch: addUsersSave
            	},		
                content: $scope.task.users,
                new: {},
                isEdit: true,
                config: {},
                origin: 'users-task'
            }
            
            $scope.$on('annexaBoxUsersDelete', function (event, args) {
                if(args.origin && args.origin == 'users-task' && args.removedId) {
                	var index =  $linq(TaskFactory.task.users).indexOf("x => x.id == '" + args.removedId + "'");
                	TaskFactory.deleteTaskUsers(TaskFactory.task.id, args.removedId)
                    .then(function () {
                    	if (index != -1)
                    		TaskFactory.task.users.splice(index, 1);
                    }).catch(function(error) {
                        console.error(error);
                    });
                }
            });
            
            $scope.addressBox = {
            	permissions: (isOpen && !$scope.isManage && !$scope.isDuplicatedTask) ? { new: ['create_task'], view: ['create_task'] } : {},
				search: { placeholder: 'global.literals.placeholderSearchAddressBox', advanced: true, saveSearch: addAddressSave },
				new: {},
				content: $scope.task.addresses,
				config: {},
				origin: 'address-task'
            };
            
            if(!TerritoryFactory.canCreateAddress()) {
            	$scope.addressBox.new = undefined;
            }

            $scope.subTaskBox = {
            	boxTitle: 'global.tasks.literals.subtasks',
            	permissions: (isOpen && !$scope.isManage && !$scope.isDuplicatedTask) ? { new: ['create_task'], view: ['create_task'] } : {},
                search: { placeholder: 'global.literals.placeholderSearchTaskBox', advanced: true, saveSearch: addSubtaskSave },
                content: [],
                new: {},
                isEdit: true,
                config: {},
                origin: 'subtask-task'
            }

            $scope.duplicatedTaskBox = {
            	boxTitle: 'global.tasks.literals.duplicatedTasks',
            	permissions: (isOpen && !$scope.isManage && !$scope.isDuplicatedTask) ? { new: ['create_task'], view: ['create_task'] } : {},
                search: { placeholder: 'global.literals.placeholderSearchTaskBox', advanced: true, saveSearch: addDuplicatedTaskSave },
                content: [],
                new: {},
                isEdit: true,
                config: {},
                origin: 'duplicated-task'
            }

            $scope.dossierTransactionBox.boxDefinition.content = $scope.task.dossierTransactions;
            $scope.registerInputBox.boxDefinition.content = $scope.task.registerEntryInputs;
            $scope.registerOutputBox.boxDefinition.content = $scope.task.registerEntryOutputs;
            $scope.documentBox.boxDefinition.content = $scope.task.documents;
            $scope.attachmentBox.boxDefinition.content = $scope.task.attachments;
            $scope.attachmentBox.boxDefinition.task = $scope.task;
            $scope.attachmentBox.boxDefinition.viewOrDownloadDocument = TaskFactory.downloadAttach;
            
            $scope.commentBox.boxDefinition.task = $scope.task;
            if($scope.task.taskComments){
                var commentsTask = $linq($scope.task.taskComments).orderBy("x => x.createdDate").toArray();
                $scope.task.taskComments.length = 0;
                _.forEach(commentsTask, function(value){
                    $scope.task.taskComments.push(value);
                });

            }
            $scope.commentBox.boxDefinition.content = $scope.task.taskComments;
            $scope.thirdBox.boxDefinition.content = $scope.task.thirds;
            if ($scope.thirdBox.boxDefinition.content) {
                _.forEach($scope.thirdBox.boxDefinition.content, function (value, key) {
                    var taskThird = new ThirdAddress(value.third, Language.getActiveColumn());
                    $scope.thirdBox.boxDefinition.content[key].addresses = taskThird.getAdressesHtml()
                });
            }

            if($scope.task.relatedTasks){
                _.forEach($scope.task.relatedTasks, function (value) {
                    var taskAux = angular.copy(value.relatedTaskInit);
                    taskAux.idOriginal = value.id;
                    $scope.taskBox.content.push(taskAux);
                });
            }
            
            if($scope.task.subTasks){
                _.forEach($scope.task.subTasks, function (value) {
                    var taskAux = angular.copy(value.task);
                    if (value.managed) {
                        $scope.subTaskBox.content.push(taskAux);
                    } else {
                        $scope.duplicatedTaskBox.content.push(taskAux);
                    }
                });
            }

            $scope.$on('updateSubtaskBoxesTask', function(event, args) {
                if(args.task.subTasks){
                    $scope.subTaskBox.content.length = 0;
                    $scope.duplicatedTaskBox.content.length = 0;
                    _.forEach(args.task.subTasks, function (value) {
                        var taskAux = angular.copy(value.task);
                        if (value.managed) {
                            $scope.subTaskBox.content.push(taskAux);
                        } else {
                            $scope.duplicatedTaskBox.content.push(taskAux);
                        }
                    });
                }
            });
            
            $scope.$on('annexaBoxTasksDelete', function (event, args) {
            	if (args.origin && args.removedId) {
            		switch (args.origin) {
            			case 'task-task':
            				var index =  $linq(TaskFactory.task.relatedTasks).indexOf("x => x.relatedTaskInit.id == '" + args.removedId + "'");
                        	TaskFactory.deleteTaskRelatedTask(TaskFactory.task.id, TaskFactory.task.relatedTasks[index].id).then(function () {
                            	if (index != -1)
                            		TaskFactory.task.relatedTasks.splice(index, 1);
                            }).catch(function(error) {
                                console.error(error);
                            });
                        	break;
            			case 'subtask-task':
            			case 'duplicated-task':
                            var index = $linq(TaskFactory.task.subTasks).indexOf("x => x.task.id == " + args.removedId + " && x.managed == " + (args.origin == 'subtask-task' ? true : false));
                        	TaskFactory.deleteTaskParentTask(TaskFactory.task.id, TaskFactory.task.subTasks[index].id).then(function () {
                            	if (index != -1) {
                            		TaskFactory.task.subTasks.splice(index, 1);
                            	}
                            }).catch(function (error) {
                            	console.log(error);
                            });
            				break;
            		}
            	}
            });
            
            $scope.$on('annexaBoxAddressDelete', function (event, args) {
                if(args.origin && args.origin == 'address-task' && args.removedId) {
                	var index =  $linq(TaskFactory.task.addresses).indexOf("x => x.id == '" + args.removedId + "'");
                	TaskFactory.deleteTaskAddress(TaskFactory.task.id, args.removedId)
                    .then(function () {
                    	if (index != -1)
                    		TaskFactory.task.addresses.splice(index, 1);
                    }).catch(function(error) {
                        console.error(error);
                    });
                }
            });

            $scope.updateTask = function(val, prop, close, modal){
                var taskAux = angular.copy($scope.task);
                if(prop) {
                	switch (prop) {
                		case 'user':
                            if(val && val.user && val.user.id) {
                                taskAux[prop] = {id:val.user.id};
                            } else {
                                return $filter('translate')('global.validation.required');
                            }
                            if (taskAux.profile) taskAux.profile = null;
                            break;
                		case 'profile':
                            if(val && val.id) {
                                taskAux[prop] = {id:val.id};
                            } else {
                                return $filter('translate')('global.validation.required');
                            }
                            if (taskAux.user) taskAux.user = null;
                            break;
                		case 'parentTasks':
                    		if(val && val.id) {
                    			taskAux[prop] = [{ parentTask: {id:val.id}, managed: $scope.task.managed }];
                    		} else {
                    			taskAux[prop] = [];
                    		}
                    		break;
                		case 'taskEndDate':
                            if($rootScope.LoggedUser && $rootScope.LoggedUser.entity && $rootScope.LoggedUser.entity.holidays) {
                                taskAux.days = HelperService.getWorkingDays(new Date(taskAux.createdDate), val, $rootScope.LoggedUser.entity.holidays);
                            } else {
                                taskAux.days = HelperService.getWorkingDays(new Date(taskAux.createdDate), val, undefined);
                            }
                            break;
                		case 'email':
                        	if (val != '' && !HelperService.validarEmail(val)) {
                        		return $filter('translate')("global.validation.email");
                        	} else if (val != '') {
                        		taskAux[prop] = val;
                        	} else {
                        		taskAux[prop] = null;
                        	}
                			break;
                		case 'phone':
                			if (val == '') {
                				taskAux[prop] = null;
                			} else {
                				taskAux[prop] = val;
                			}
                			break;
                        default:
                            if(val) {
                                taskAux[prop] = val;
                            } else {
                                return $filter('translate')('global.validation.required');
                            }
                        	break;
                	}

                    TaskFactory.updateTask(taskAux).then(function (data) {
                		if (prop == 'parentTasks') {
                			TaskFactory.task.parentTask = data.parentTasks[0];
                    	}
                    	
                    	$state.reload();
                        if(close) {
                            modal.close();
//                            $state.go('annexa.tasks');
                        }
                     }).catch(function (error) {
                        var a = 0;
                     });
                };
            };

            $scope.cancel = function () {
                if($state.current.name == 'annexa.tasks.createdTasks.edit'){
                    $state.transitionTo('annexa.tasks.createdTasks');
                }else{
                    $state.transitionTo('annexa.tasks.assignedTasks');
                }
            }

            $scope.auditTask = function () {
                if ($scope.task && $scope.task.id) {
                    window.open($state.href('annexa.admin.audit_task', {"objectId": $scope.task.id }), '_blank');
                }
            };
            
            var assignUserOrProfileChangeStateTaskModal = function(modal, user, profile, disabled) { 
            	if (user) {
                	modal.annexaFormly.model.modal_body.assignTo = 'user';
                	var name = user.name + ' ' + user.surename1;
                    if(user.surename2) {
                        name += ' ' + user.surename2;
                    }
                    modal.annexaFormly.model.modal_body.newUser = { 'id':  user.id, 'user': user, 'value': name };
                } else if (profile) {
                	modal.annexaFormly.model.modal_body.assignTo = 'profile';
                	modal.annexaFormly.model.modal_body.newProfile = profile.id;
                }
            	
            	_.forEach(modal.annexaFormly.fields[0].fieldGroup, function(fieldGroup){
 					if(fieldGroup && fieldGroup.templateOptions && (fieldGroup.key == 'assignTo' || fieldGroup.key == 'newUser' || fieldGroup.key == 'newProfile' )){
 						fieldGroup.templateOptions.disabled = disabled;
 					}
            	});
            }
            
            $scope.changeStateTaskEdit = function() {
            	var modal = angular.copy(TasksModals.changeStateTask);
            	modal.annexaFormly.model = {};
            	modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                
                //Asigna los valores al modal
                assignUserOrProfileChangeStateTaskModal(modal, $scope.task.user, $scope.task.profile, false);
                var possibleStatuses = $linq($scope.taskStatusesEdit).where("x => x.id != " + $scope.task.status.id).toArray();
                modal.annexaFormly.fields[0].fieldGroup[3].templateOptions.options = possibleStatuses;
                
                modal.annexaFormly.fields[0].fieldGroup[3].templateOptions.onSelected = function(status){
                	if (status.user || status.profile) {
                		assignUserOrProfileChangeStateTaskModal(modal, status.user, status.profile, true);
                	} else {
                		assignUserOrProfileChangeStateTaskModal(modal, null, null, false);
                	}
                }
                
                var changeStateTaskComplete = function (){
                	var model = modal.annexaFormly.model.modal_body;
                	var taskCopy = angular.copy($scope.task); 
                	
                	if (model.assignTo && model.assignTo == 'user' && model.newUser) {
                		taskCopy.user = model.newUser.user;
                		taskCopy.profile = undefined;
                    } else if (model.assignTo && model.assignTo == 'profile' && model.newProfile) {
                    	taskCopy.profile = { 'id':  model.newProfile };
                    	taskCopy.user = undefined;
                    }
                	
                	if (model.newStatus) {
                		taskCopy.status = $linq($scope.taskStatusesEdit).singleOrDefault(undefined, "x => x.id == " + model.newStatus);
                	}
                
                	TaskFactory.updateTask(taskCopy).then(function (data) {
                		$state.reload();
                		modal.close();
    				}).catch(function (error) {
    					console.log(error);
    					modal.alerts.push({msg:'global.task.changeStateError'});
    				});
                }
                
                AnnexaFormlyFactory.showModal("modalChangeStateTask", modal, changeStateTaskComplete, false);
            };
            
            $scope.reassignTaskEdit = function() {
            	$scope.reassignTask($scope.task.id);
            }

            $scope.modifyTaskType = function() {
            	var data = {
                    row: true,
                    colClass: ' col-sm-12',
                    labelClass: 'label-strong'
                };
                var modal = angular.copy(TasksModals.modifyTaskType);
                modal.annexaFormly = new AnnexaFormly();
                modal.alerts = [];
                var fields = [];
                var otherTaskTypes = $linq($scope.taskTypes).where("x => x.id != " + $scope.task.type.id).toArray();
                var taskType = modal.annexaFormly.createField('type','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.taskType','id',$scope.languageColumn,otherTaskTypes,true),data);
                fields.push(taskType);
                
                taskType.templateOptions.onSelected = function(type){
                	var indexFieldStatus = $linq(fields).indexOf("x => x.key == 'status'");
                    if(indexFieldStatus != -1){
                    	fields.splice(indexFieldStatus, 1);
                    }

                	var statuses = [];
                	if (type) {
                		if (type.statuses) {
                			statuses = type.statuses;
                		}
                		if (type.parent && type.parent.statuses) {
                			statuses = statuses.concat(type.parent.statuses);
                			statuses = $linq(statuses).orderBy("x => x." + $scope.languageColumn, linq.caseInsensitiveComparer).toArray();
                		}

                		var defaultStatus = $linq(statuses).firstOrDefault(undefined, "x => x.defaultValue == true");
                		if (defaultStatus) {
                			modal.annexaFormly.model.modal_body.status = defaultStatus.id;
                		} else {
                			modal.annexaFormly.model.modal_body.status = undefined;
                		}
                	}

                    var data = {
                        row: true,
                        colClass: 'col-sm-12',
                        labelClass: 'label-strong small m-b-0 ',
                        extralabel: true
                    };
                	
                	var status = modal.annexaFormly.createField('status','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.taskStatus','id',$scope.languageColumn,statuses,true),data);
                	fields.splice(indexFieldStatus, 0, status);
                };
                fields.push(modal.annexaFormly.createField('status','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.taskStatus','id',$scope.languageColumn,[],true),data));

                taskType.data.clear = function ($event, model, key, $select, to) {
                    $event.stopPropagation();
                    model[key] = undefined;
                    if ($select) {
                        $select.selected = undefined;
                        $select.search = undefined;
                    }
                    if(to.onSelected) {
                        to.onSelected(undefined);
                    }
                }
                

                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.extra = this;

                //region Camps Personalitzat
                if(taskType && taskType.templateOptions && taskType.templateOptions.options && taskType.templateOptions.options.length > 0) {
                    _.forEach(taskType.templateOptions.options, function (type) {
                    	var setCustomFields = function(field){
                            var formCF = new AnnexaFormly();
                            formCF = CustomFieldFactory.addFormlyFieldRow(formCF, field.customField, field.required, type.id + '_' + field.customField.id);
                            formCF.fields[0].className = '';
                            formCF.fields[0].hideExpression = function ($viewValue, $modelValue, scope) {
                                if (!field.noEditable) {
                                    return !(type.id == scope.model.type);
                                } else {
                                    return true;
                                }
                            }

                            fieldsCF.push(formCF.fields[0]);
                            var cfFound = $linq($scope.task.customFields).singleOrDefault(undefined, "x => x.customField.id == " + field.customField.id);
                            if (cfFound) {
                            	if(cfFound.customField.frontendType == 'CHECKBOX'){
                                    if(cfFound.customField.listValues){
                                        _.forEach(cfFound.customField.listValues, function(option){
                                            if( modal.annexaFormly.model.modal_body != undefined ) {
                                                if (_.contains(cfFound.valueFromJSON, option['value'])) {
                                                	modal.annexaFormly.model.modal_body[('cf_' + type.id + '_' + field.customField.id) + '_' + option.value] = true;
                                                } else {
                                                	modal.annexaFormly.model.modal_body[('cf_' + type.id + '_' + field.customField.id) + '_' + option.value] = false;
                                                }
                                            }
                                        });
                                    }
                                } else {
                                	modal.annexaFormly.model.modal_body['cf_' + type.id + '_' + field.customField.id] = cfFound.valueFromJSON;	
                                }
                            } else {
                                modal.annexaFormly.model.modal_body['cf_' + type.id + '_' + field.customField.id] = CustomFieldFactory.calculateValueCustomField(field, modal.annexaFormly.model.modal_body, ('cf_' + type.id + '_' + field.customField.id));                            		                            	
                            }
                    	}

                        var custom_fields = $linq(type.customFields).orderBy("x => x.viewOrder").toArray();
                        var fieldsCF = [];
                        _.forEach(custom_fields, function (customField) {
                        	setCustomFields(customField);
                        });
                        //afegim també els customField dels tipus de tasca pare
                        if (type.parent) {
                        	var custom_fields_parent = $linq(type.parent.customFields).orderBy("x => x.viewOrder").toArray();
                            _.forEach(custom_fields_parent, function (customFieldsParentTaskType) {
                            	setCustomFields(customFieldsParentTaskType);
                            });
                        }
                        fields.push.apply(fields, fieldsCF);
                    });
                }
                //endregion
                
                var modifyTaskTypeConfirm = function(){
                	var model = modal.annexaFormly.model.modal_body;
                    
                    var taskAux = angular.copy($scope.task);
                    taskAux.id = undefined;
                    taskAux.type = { id: model.type };
                    taskAux.status = { id: model.status };

                    //s'han de setejar els llistats per ser una nova tasca
                    taskAux.taskComments = [];
                    taskAux.relatedTasks = [];
                    taskAux.thirds = [];
                    taskAux.users = [];
                    taskAux.addresses = [];
                    
                    var setTaskLists = function() {
                    	_.forEach($scope.task.taskComments, function(taskComment) {
                    		taskAux.taskComments.push({comments: taskComment.comments});
                    	});
                    	_.forEach($scope.task.relatedTasks, function(relatedTask){
                    		taskAux.relatedTasks.push({relatedTask: {id: relatedTask.relatedTaskInit.id}});
                		});
                    	_.forEach($scope.task.thirds, function(taskThird){
                    		taskAux.thirds.push({third: taskThird.third});
                		});
                    	_.forEach($scope.task.users, function(taskUser){
                    		taskAux.users.push({user: taskUser.user});
                		});
                    	_.forEach($scope.task.addresses, function(taskAddress){
                    		taskAux.addresses.push({address: taskAddress.address});
                		});
                    }
                    
                    setTaskLists();
                    
                	var setValueCustomFields = function(customFields, taskType, cf) {
                        _.forEach(customFields, function(taskTypeField) {
                            var taskCustomField = {
                                customField: { id: taskTypeField.customField.id },
                                required: taskTypeField.required,
                                viewOrder: taskTypeField.viewOrder,
                                value: taskTypeField.value,
                                noEditable: taskTypeField.noEditable
                            };

                            var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + taskType.id  + "_" + taskTypeField.customField.id + "'");

                            if(cfValue) {
                                if(taskTypeField.customField.frontendType == 'CHECKBOX'){
                                    var custom_field_selected = $linq(cf).where(function(x){
                                        if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
                                    ).toArray();
                                    if(custom_field_selected && custom_field_selected.length > 0){
                                        taskCustomField.value = '[';
                                        _.forEach(custom_field_selected, function (item, key) {
                                            if(item.value) {
                                                if(taskTypeField.customField && taskTypeField.customField.listValues && taskTypeField.customField.listValues.length > 0) {
                                                    var custom_field_value_selected = $linq(taskTypeField.customField.listValues).where(function(x){
                                                        if(item.id.endsWith("_"+x.value)){return true}else{return false}}
                                                    ).toArray();
                                                    if(custom_field_value_selected && custom_field_value_selected.length > 0){
                                                        taskCustomField.value += ((taskCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
                                                    }
                                                }
                                            }
                                        });
                                        taskCustomField.value += ']';
                                    }
                                }else if(Array.isArray(cfValue.value)) {
                                    if(taskTypeField.customField.frontendType == 'MULTIPLESELECT') {
                                        taskCustomField.value = '[';

                                        _.forEach(cfValue.value, function (item) {
                                            taskCustomField.value += ((taskCustomField.value == '[') ? item : ',' + item);
                                        });

                                        taskCustomField.value += ']';
                                    } else {
                                        taskCustomField.value = cfValue.value[0];
                                    }
                                } else {
                                    if(cfValue.value instanceof Date) {
                                        taskCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
                                    } else {
                                        taskCustomField.value = cfValue.value;
                                    }
                                }
                            }

                            taskAux.customFields.push(taskCustomField);
                        });
                	}
                	
                    var cf = CustomFieldFactory.getModelValues(model);
                    taskAux.customFields = [];
                    var tType = $linq(GlobalDataFactory.taskTypes).singleOrDefault(undefined, "x => x.id == " + taskAux.type.id);
                    if(tType && cf && cf.length > 0) {
                    	setValueCustomFields(tType.customFields, tType, cf);
                    	
                    	//setegem també els valors dels customField dels tipus de tasca pare
                        if (tType.parent) {
                        	setValueCustomFields(tType.parent.customFields, tType, cf);
                        }
                    }

                	DialogsFactory.confirm('global.tasks.literals.modifyTaskType', 'global.tasks.literals.modifyTaskTypeConfirm').then(function (dataAux) {
                        TaskFactory.modifyTaskType(taskAux, $scope.task.id).then(function (data) {
                            modal.close();
                            if($state.current.name == 'annexa.tasks.createdTasks.edit'){
                                $state.transitionTo('annexa.tasks.createdTasks.edit', { "task" : data.id });
                            }else{
                                $state.transitionTo('annexa.tasks.assignedTasks.edit', { "task" : data.id });
                            }
        				}).catch(function (error) {
        					console.log(error);
        				});
                      }).catch(function (data) {
                      	//Empty
                      });        	
                }
                
                AnnexaFormlyFactory.showModal("modalModifyTaskType", modal, modifyTaskTypeConfirm, false);
            }

            $scope.linkParentTask = function() {
                var modal = angular.copy(TasksModals.parentTaskAdd);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                if ($scope.task.parentTask) {
                	modal.annexaFormly.model.modal_body.parent = ($scope.isSubtask ? $filter('translate')('global.tasks.literals.childTask') : $filter('translate')('global.tasks.literals.duplicatedTask')) + ' "' + $scope.task.parentTask.description + '"';
                }
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = { readOnly: false };
                modal.languageColumn = $scope.languageColumn;
                
                var proccesResult = function (object) {
                	var assigned = '';

                    if(object.user) {
                        assigned += object.user.name + ' ' + object.user.surename1 + (object.user.surename2 ? ' ' + object.user.surename2 : '') ;
                    }
                    if(object.profile) {
                        assigned += object.profile[modal.languageColumn];
                    }

                    return object.description + (assigned ? ' (' + assigned + ')' : '');
                };

                modal.annexaFormly.fields[0].fieldGroup[3].templateOptions.search = function(val) {
                    var filter = {
                    	taskNumber: val.val,
                    	description: val.val
                    };
                    var added = [];
                    added.push($scope.task.id)
                    var additional_filter = {
                    	loggedUser: $rootScope.LoggedUser.id,
                    	langColumn: $scope.languageColumn,
                    	added: added,
                    	assignedTask: true,
                    	finished: false
                    };
                    
                    return RestService.filterData('tasks', 'Task', filter, additional_filter, 0, 10, '', 3)
                        .then(function(data) {
                            var response = [];

                            _.forEach(data.data.content, function (value) {
                                response.push({ id: value.id, object: JSOG.encode(value), value: proccesResult(value) });
                            });

                            response = new SortedArray(response, 'value').sort();

                            return response;
                        });
                };
                
                modal.annexaFormly.fields[0].fieldGroup[3].templateOptions.advancedSearch = function() {
                    $scope.tfilterTask  = new TableFilter();
                    $scope.tfilterTask.addElement('taskNumber', 'text', 0, 'global.literals.code').setFilterCallAux(true);
                    $scope.tfilterTask.addElement('description', 'text', 1, 'global.literals.task_name').setFilterCallAux(true);
                    $scope.tfilterTask.addElement('type', 'select', 2, 'global.literals.taskType', 3, HelperService.addAllSelect(GlobalDataFactory.taskTypes, $scope.languageColumn, Language.getActiveColumn()), true, $scope.languageColumn, true, undefined, Language.getActiveColumn()).setFilterCallAux(true);
                    //Altres criteris
                    $scope.tfilterTask.addElement('createdUser', 'text', 3, 'global.literals.started_for').setFilterCallAux(true).setUserChosenFilter(true);
                    $scope.tfilterTask.addElement('user', 'text', 4, 'global.literals.assigned_to').setFilterCallAux(true).setUserChosenFilter(true);
                    $scope.tfilterTask.addElement('startDates', 'dateRange', 5, 'global.literals.startDate').setFilterCallAux(true).setUserChosenFilter(true);
                    $scope.tfilterTask.addElement('endDates', 'dateRange', 6, 'global.literals.endDate').setFilterCallAux(true).setUserChosenFilter(true);
                    $scope.tfilterTask.addElement('expired', 'select', 7, 'global.literals.expiredSate', 3, HelperService.addAllSelect($scope.expiredStates, 'name', Language.getActiveColumn()), true, 'name', true, undefined, Language.getActiveColumn()).setFilterCallAux(true).setUserChosenFilter(true);
                    $scope.tfilterTask.addElement('status', 'select-multiple', 8, 'global.literals.globalStatus', 3, HelperService.addAllSelect(TaskFactory.taskStatuses, 'name', Language.getActiveColumn()), true, 'name', true, undefined, Language.getActiveColumn()).setFilterCallAux(true).setUserChosenFilter(true);
                    $scope.tfilterTask.addElement('specificStatus', 'select-multiple', 9, 'global.literals.status', 3, $scope.getSpecificStatuses(), true, 'name', true, undefined, Language.getActiveColumn()).setFilterCallAux(true).setUserChosenFilter(true);
                    $scope.tfilterTask.addElement('thirdName', 'text', 10, 'global.thirds.literals.third').setFilterCallAux(true).setUserChosenFilter(true);
                    $scope.tfilterTask.addElement('address', 'text', 11, 'global.territory.list.address').setFilterCallAux(true).setUserChosenFilter(true);
                    $scope.tfilterTask.addElement('meta', 'text', 12, 'global.literals.meta').setFilterCallAux(true).setUserChosenFilter(true);
                    $scope.tfilterTask.loadLocalData([],undefined, undefined, Language.getActiveColumn());

                    var tdDefTask = new TableData('tasks', 'Task', './api/tasks/byfilter').setSortName('taskNumber').setSort([[1, 'asc']]).setSkipFirstSearch(true);

                    var TaskTypeColumn = new DatabaseTranslatedColumn($filter, 'global.literals.taskType', Language.getActiveColumn());

                    var tabledataColumnsTask = [
                        DTColumnBuilder.newColumn(null).renderWith(function(data, type, full, meta){
                            return '<input type="radio" id="'+full.id+'" name ="selected" data-ng-model="radioSelected" data-ng-value="'+ full.id +'" aria-label="{{ \'global.literals.select\' | translate }}">';
                        }).notSortable(),
                        DTColumnBuilder.newColumn('taskNumber').withTitle($filter('translate')('global.literals.code')),
                        DTColumnBuilder.newColumn('description').withTitle($filter('translate')('global.literals.task_name')),
                        DTColumnBuilder.newColumn('type.' + TaskTypeColumn.getColumn()).withTitle(TaskTypeColumn.getTitle())
                    ];

                    var advancedModalDefinition = new BoxAdvancedSearchModalDefinition('parentTaskBox', 'global.literals.advanced_search_tasks', $scope.tfilterTask, tdDefTask, tabledataColumnsTask, undefined, undefined, modal.annexaFormly.fields[0].fieldGroup[3].templateOptions.advancedSearchAdd, modal).changeSize('modal-lg');
                    advancedModalDefinition.added = [];
                    advancedModalDefinition.added.push($scope.task.id);
                    advancedModalDefinition.assignedTask = true;

                    //Definim una funció pròpia perquè ens interesa carregar el desplegable dels estats específics de forma dinàmica
                    advancedModalDefinition.addFilterCallAux = function (filterCall, filterCallAux) {
                    	if ($scope.tfilterTask) {
                    		var filterSpecificStatusAux = $scope.tfilterTask['specificStatus'];
               				filterSpecificStatusAux.listValues = $scope.getSpecificStatuses(filterCallAux.status);
               				_.forEach(filterSpecificStatusAux.listValues, function(value, index) {
               					value.name = value[$scope.languageColumn];
           	                });

               				if(filterCallAux.specificStatus && filterCallAux.specificStatus.length > 0){
               					var specificListFilter = [];
               	                _.forEach(filterCallAux.specificStatus, function(value, index) {
               	   					if($linq(filterSpecificStatusAux.listValues).contains(value, function(x,y){
               	   						if(x && x.id && y.id == x.id) { return true; } else { return false; } 
               	   					})) {
               	   						specificListFilter.push({id: value[$scope.languageColumn]});
               	   						//Actualitzem el llistat per evitar duplicacions
               	   						var indexSpecificStatus = $linq(filterSpecificStatusAux.listValues).indexOf("x => x.id == " + value.id);
               	                    	if (indexSpecificStatus != -1)
               	                    		filterSpecificStatusAux.listValues.splice(indexSpecificStatus, 1);
               	   					}
               	                });

               	                //Actualitzem el model amb els valors carregats al llistat specificListFilter
               	                filterSpecificStatusAux.model = $linq(filterSpecificStatusAux.model).where(function(specificStatus){
            	        			return $linq(specificListFilter).contains(specificStatus[$scope.languageColumn], function(x,y){
            	        				if(x && x.id && y == x.id) { return true; } else { return false; }
            	        			});
               	                }).toArray();
               	                
               	                filterCallAux.specificStatus = specificListFilter;
                    		} else {
                				filterSpecificStatusAux.model = {};
                    		}
                        }
                    };

                    AnnexaModalFactory.showBoxAdvancedFilter(advancedModalDefinition);
                };

                
                var linkParentTaskComplete = function(){
                    var model = modal.annexaFormly.model.modal_body;
                    
                    $scope.task.managed = (model.relationType == 'subtask') ? true : false;
                    $scope.updateTask(model.parentTask, 'parentTasks', true, modal);
                };
                AnnexaFormlyFactory.showModal("modalLinkParentTask", modal, linkParentTaskComplete, false);
            }
        }])
		.controller('TaskByNumberController',['$scope', 'TaskFactory', '$state', '$stateParams', function ($scope, TaskFactory, $state, $stateParams) {
	    	if(TaskFactory.byNumberId && TaskFactory.numberIsAssigned === true && TaskFactory.numberIsManaged === false){
	    		$state.go('annexa.tasks.assignedTasks.edit', {"task" : TaskFactory.byNumberId});
	        }else if(TaskFactory.byNumberId && TaskFactory.numberIsAssigned === false && TaskFactory.numberIsManaged === false){
				$state.go('annexa.tasks.createdTasks.edit', {"task" : TaskFactory.byNumberId});
	        }else if(TaskFactory.byNumberId && TaskFactory.numberIsAssigned === false && TaskFactory.numberIsManaged === true){
				$state.go('annexa.tasks.manageTasks.edit', {"task" : TaskFactory.byNumberId});
	        }else{
	        	$state.go('annexa.dashboard');
	        }
	    }]);
