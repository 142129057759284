/**
 * Created by osirvent on 08/04/2016.
 */
angular
    .module('annexaApp')
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
	        .state('annexa.tasks', {
	            url: '/tasks',
	            redirectTo: 'annexa.tasks.assignedTasks',
	            views: {
	                "": {
	                    controller: 'TasksController',
	                    templateUrl: './views/layout/tasks.html'
	                }
	            },
	            data: {
	                title: 'global.toptitletasks',
	                displayName: 'global.breadcrumbs.tasks',
	                authenticate: true
	            },
	            resolve: {
	                taskdata: ['TaskFactory', function (TaskFactory) {
	                    return TaskFactory.getTaskData();
	                }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }],
	                taskDocumentsData: ['DccumentsFactory', function (DccumentsFactory) {
	                    return DccumentsFactory.getDocumentLists();
	                }],
	                getTerritorytLists: ['TerritoryFactory', function (TerritoryFactory) {
	                    return TerritoryFactory.initializeLists();
	                }]
	            }
	        })
	        .state('annexa.tasks.createdTasks', {
	            url: '/createdTasks/:type',
	            views: {
	                "maintask@annexa.tasks": {
	                    controller: 'CreatedTasksController',
	                    templateUrl: './views/layout/createdTasks.html'
	                }
	            },
	            data: {
	                displayName: 'global.literals.tasksCreated',
	                authenticate: true,
	                permissions: {
	                    only: 'create_task'
	                }
	            }
	        })
	        .state('annexa.tasks.createdTasks.new', {
	            url: '/new',
	            views: {
	                "maintask@annexa.tasks": {
	                    controller: 'NewTasksController',
	                    templateUrl: './views/layout/new_task.html'
	                }
	            },
	            data: {
	                displayName: 'global.literals.newf',
	                authenticate: true,
	                permissions: {
	                    only: 'create_task'
	                }
	            },
	            resolve: {
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
	                }]
	            }
	        })
	        .state('annexa.tasks.createdTasks.edit', {
	            url: '/edit/:task',
	            views: {
	                "maintask@annexa.tasks": {
	                    controller: 'EditTasksController',
	                    templateUrl: './views/layout/edit_task.html'
	                }
	            },
	            data: {
	                displayName: 'global.literals.newf',
	                authenticate: true,
	                permissions: {
	                    only: 'create_task'
	                }
	            },
	            resolve: {
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }],
	                taskdata: ['TaskFactory', '$rootScope', '$stateParams', '$timeout', '$q', '$state', function (TaskFactory, $rootScope, $stateParams, $timeout, $q, $state) {
	                    if($stateParams.task) {
	                        $rootScope.showLoadingdivSignin = true;
	                        return TaskFactory.getTask($stateParams.task);
	                    } else {
	                        $timeout(function() { $state.go('annexa.tasks.createdTasks'); });
	                        return $q.reject("No task");
	                    }
	                }]
	            }
	        })
	        .state('annexa.tasks.assignedTasks', {
	            url: '/assignedTasks/:type',
	            views: {
	                "maintask@annexa.tasks": {
	                    controller: 'AssignedTasksController',
	                    templateUrl: './views/layout/assignedTasks.html'
	                }
	            },
	            data: {
	                displayName: 'global.literals.tasksAssigned',
	                authenticate: true,
	                permissions: {
	                    only: 'view_tasks'
	                }
	            }
	        })
	        .state('annexa.tasks.manageTasks', {
	            url: '/manage_tasks/:type',
	            views: {
	                "maintask@annexa.tasks": {
	                    controller: 'ManageTasksController',
	                    templateUrl: './views/layout/manageTasks.html'
	                }
	            },
	            data: {
	                displayName: 'global.literals.manage',
	                authenticate: true,
	                permissions: {
	                    only: 'manage_tasks'
	                }
	            }
	        })
	        .state('annexa.tasks.manageTasks.edit', {
	            url: '/edit/:task',
	            views: {
	                "maintask@annexa.tasks": {
	                    controller: 'EditTasksController',
	                    templateUrl: './views/layout/edit_task.html'
	                }
	            },
	            data: {
	                displayName: 'global.literals.see',
	                authenticate: true,
	                permissions: {
	                    only: 'manage_tasks'
	                }
	            },
	            resolve: {
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }],
	                taskdata: ['TaskFactory', '$rootScope', '$stateParams', '$timeout', '$q', '$state', function (TaskFactory, $rootScope, $stateParams, $timeout, $q, $state) {
	                    if($stateParams.task) {
	                        $rootScope.showLoadingdivSignin = true;
	                        return TaskFactory.getTask($stateParams.task);
	                    } else {
	                        $timeout(function() { $state.go('annexa.tasks.createdTasks'); });
	                        return $q.reject("No task");
	                    }
	                }]
	            }
	        })
	        .state('annexa.tasks.assignedTasks.edit', {
	            url: '/edit/:task',
	            views: {
	                "maintask@annexa.tasks": {
	                    controller: 'EditTasksController',
	                    templateUrl: './views/layout/edit_task.html'
	                }
	            },
	            data: {
	                displayName: 'global.literals.newf',
	                authenticate: true,
	                permissions: {
	                    only: 'view_tasks'
	                }
	            },
	            resolve: {
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }],
	                taskdata: ['TaskFactory', '$rootScope', '$stateParams', '$timeout', '$q', '$state', function (TaskFactory, $rootScope, $stateParams, $timeout, $q, $state) {
	                    if($stateParams.task) {
	                        $rootScope.showLoadingdivSignin = true;
	                        return TaskFactory.getTask($stateParams.task);
	                    } else {
	                        $timeout(function() { $state.go('annexa.tasks.assignedTasks'); });
	                        return $q.reject("No task");
	                    }
	                }]
	            }
            })
			.state('annexa.tasks.byNumber', {
	            url: '/number/:number',
	            views: {
	                "maintask@annexa.tasks": {
	                    controller: 'TaskByNumberController',
	                    templateUrl: './views/layout/tasks.html'
	                }
	            },
	            data: {
	                displayName: 'global.literals.newf',
	                authenticate: true,
	                permissions: {
	                    only: ['manage_tasks', 'view_tasks', 'create_task']
	                }
	            },
	            resolve: {
	                 taskdata: ['TaskFactory', '$rootScope', '$stateParams', '$timeout', '$q', '$state', function (TaskFactory, $rootScope, $stateParams, $timeout, $q, $state) {
	                    if($stateParams.number) {
	                        $rootScope.showLoadingdivSignin = true;
	                        return TaskFactory.getTaskByNumber($stateParams.number);
	                    } else {
	                        $timeout(function() { $state.go('annexa.dashboard'); });
	                        return $q.reject("No task");
	                    }
	                }]
	            }
            })
    }]);